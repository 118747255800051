import React from "react";
import { createContext, useState } from "react";

export const UIContext = createContext({
    searchKey: "",
    setSearchKey: () => {},
    searchedData: [],
    setSearchedData: () => {}
});

export default function ContextProvider({children}) {
    const [ searchKey, setSearchKey ] = useState("");
    const [ searchedData, setSearchedData ] = useState([]);

    const data = {
        searchKey,
        setSearchKey,
        searchedData,
        setSearchedData
    }
    return(
        <UIContext.Provider value={data}>
            {children}
        </UIContext.Provider>
    )
}
